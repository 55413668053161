<template>
  <v-container fluid style="padding: 0; marging: 0" class="login">

    <v-row aling="center" justify="center" dense>
      <v-col col="12" lg="6" md="6">
        <v-card class="justify-center" outlined>
          <v-card-title class="justify-center">
            <v-img src="../../assets/logo.png" max-width="322" max-height="137" />
          </v-card-title>
          <v-card-text class="d-flex justify-center">
            <v-form style="width: 60%">
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="py-4 text-center">
                        <strong style="font-size: 25px !important; color: #1F6B9D">Inicio de sesión</strong>
                      </v-col>
                      <v-col cols="12" class="mt-8">
                        <label for=""><strong>Correo</strong></label>
                        <v-text-field v-model="email" :error-messages="emailErrors"
                          prepend-inner-icon="mdi-email-outline" color="primary" name="login"
                          placeholder="Ingresa tu correo" required @input="$v.email.$touch()"
                          @blur="$v.email.$touch()" />
                      </v-col>
                      <v-col cols="12">
                        <label for=""><strong>Contraseña</strong></label>
                        <v-text-field id="password" v-model="password" color="primary" :error-messages="passwordErrors"
                          :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye-outline'" :type="show1 ? 'text' : 'password'"
                          placeholder="Ingresar tu contraseña" prepend-inner-icon="mdi-lock-outline" name="password"
                          @input="$v.password.$touch()" @blur="$v.password.$touch()" @click:append="show1 = !show1"
                          @keyup.enter="submit" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" />
                      <v-col cols="6">
                        <p style="color: #1F6B9D;text-align: right;">Olvidé mi contraseña</p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="pa-12">
                    <v-btn large height="50" light block color="primary" dark @click="submit">
                      Ingresar
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  mixins: [validationMixin],
  validations: {
    email: { required },
    password: { required },
  },
  layout: "centered",
  components: {},
  data: () => ({
    width: 0,
    text: "",
    color: "",
    snackbar: false,
    email: "",
    password: "",
    show1: false,
    rules: {
      required: (value) => !!value || "Requerido",
    },
  }),
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email es requerido");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password es requerido");
      return errors;
    },
    ...mapGetters(['userSession'])
  },
  methods: {
    ...mapActions(["login"]),
    mensaje(snackbar, color, text) {
      this.snackbar = snackbar;
      this.color = color;
      this.text = text;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        //this.submitStatus = 'ERROR'
      } else {
        this.login({
          user: {
            email: this.email,
            password: this.password,
            device: "web",
          }
        })
          .then(() => this.$router.push(this.$getConst(this.userSession.roles[0].name)))
          .catch((err) => {
            this.mensaje(true, "error", err.errorMessage, 5000);
          });
      }
    },
  },
};
</script>

<style scoped>
.login {
  height: 100vh;
  background-image: url("../../assets/login.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
</style>
